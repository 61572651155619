import React, {useState} from 'react';
import APIService from "./APIService";
import {
    Button, Box, TextField, Tooltip, FormControlLabel, Checkbox,
    Select, MenuItem, InputLabel, FormControl, Typography, Accordion,
    AccordionSummary, AccordionDetails, Chip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useNavigate} from "react-router-dom";
import './App.css';


const Form = (props) => {
    const navigate = useNavigate();
    const [text_input, setText_input] = useState('');
    const [keywords, setKeywords] = useState([]);
    const [error, setError] = useState(null);
    const [advancedOptions, setAdvancedOptions] = useState(false);
    const [isBuilding, setIsBuilding] = useState(false);

    // Self-Determination
    const [isHumanDependent, setIsHumanDependent] = useState(false);
    const [isSelfGoalDefined, setIsSelfGoalDefined] = useState(false);
    const [isSelfGoverned, setIsSelfGoverned] = useState(false);
    const [isSelfProcessDefined, setIsSelfProcessDefined] = useState(false);

    // Organization
    const [classifier, setClassifier] = useState('Classification_nonlinear');

    // Self-Organization
    const [isReinforcing, setIsReinforcing] = useState(false);
    const [isSelfSelecting, setIsSelfSelecting] = useState(false);

    // Data and Process Management
    const [dataAccess, setDataAccess] = useState('fully');
    const [isDataRedundant, setIsDataRedundant] = useState(false);
    const [isDataHeterogenous, setIsDataHeterogenous] = useState(false);
    const [isProcessingIandK, setIsProcessingIandK] = useState(false);

    // Interaction
    const [performance, setPerformance] = useState('real-time');
    const [performanceQuality, setPerformanceQuality] = useState('productive_state');

    // Business Area
    const [businessArea, setBusinessArea] = useState('Healthcare');

    // System Orientation
    const [systemOrientation, setSystemOrientation] = useState('Centralized');

    // Approach
    const [approach, setApproach] = useState('Deep Learning');

    // onChange functions
    const handleKeywordsChange = (event) => {
        setKeywords(event.target.value.split(',').map(keyword => keyword.trim()));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);

        if (!text_input.trim()) {
            setError("Please provide a non-empty search query.");
            return;
        }

        try {
            const result = await handleSearch();
            if (result) {
                navigate("/results", {state: result});
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error.message || "An error occurred while fetching data");
        }
    };

    const handleBuildSubGraph = async () => {
        setError(null);
        setIsBuilding(true);
        try {
            if (!text_input || text_input.trim() === '') {
                throw new Error("Please provide a non-empty query before building the sub-graph.");
            }

            const result = await handleGetGraphDetails();
            console.log("Graph details:", result);
            navigate("/resultgraph", {state: result});
        } catch (error) {
            console.error("Error building sub-graph:", error);
            setError(error.message || "An error occurred while building the sub-graph");
        } finally {
            setIsBuilding(false);
        }
    };

    const handleSearch = async () => {
        try {
            if (!text_input.trim()) {
                throw new Error("Please provide a non-empty search query.");
            }
            const result = await APIService.SearchQuery(text_input);
            console.log("Search result:", result);
            return result;
        } catch (error) {
            console.error("Error during search:", error);
            throw error;
        }
    };

    const handleGetGraphDetails = async () => {
        try {
            const requestData = {
                text_input,
                keywords,
                isHumanDependent,
                isSelfGoalDefined,
                isSelfGoverned,
                isSelfProcessDefined,
                classifier,
                isReinforcing,
                isSelfSelecting,
                dataAccess,
                isDataRedundant,
                isDataHeterogenous,
                isProcessingIandK,
                performance,
                performanceQuality,
                businessArea,
                systemOrientation,
                approach
            };

            const result = await APIService.GetGraphDetails(requestData);
            console.log("Graph details:", result);
            return result;
        } catch (error) {
            console.error("Error getting graph details:", error);
            throw error;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {error && (
                <Typography color="error" sx={{mt: 2, mb: 2}}>
                    Error: {error}
                </Typography>
            )}

            <Tooltip title="Provide a detailed description of the opportunity or problem you want to address">
                <TextField
                    label="Query (provide a detailed opportunity description)"
                    multiline
                    rows={10}
                    value={text_input}
                    onChange={(e) => setText_input(e.target.value)}
                    fullWidth
                    required
                    sx={{mb: 2}}
                />
            </Tooltip>

            <Tooltip title="Enter keywords related to your query, separated by commas">
                <TextField
                    label="Keywords"
                    value={keywords.join(', ')}
                    onChange={handleKeywordsChange}
                    fullWidth
                    sx={{mb: 2}}
                />
            </Tooltip>

            <Box sx={{mb: 2}}>
                {keywords.map((keyword, index) => (
                    <Chip key={index} label={keyword} sx={{mr: 1, mb: 1}}/>
                ))}
            </Box>

            <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{mb: 2}}
            >
                Get Solution Recommendations
            </Button>

            <Accordion
                expanded={advancedOptions}
                onChange={() => setAdvancedOptions(!advancedOptions)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>Advanced Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="h6" gutterBottom>Self-Determination: the solution...</Typography>
                    <Box sx={{mb: 2}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isHumanDependent}
                                    onChange={(e) => setIsHumanDependent(e.target.checked)}
                                    name="humandependency"
                                />
                            }
                            label="is not dependent on humans"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSelfGoalDefined}
                                    onChange={(e) => setIsSelfGoalDefined(e.target.checked)}
                                    name="selfgoal"
                                />
                            }
                            label="defines goals itself"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSelfGoverned}
                                    onChange={(e) => setIsSelfGoverned(e.target.checked)}
                                    name="selfgov"
                                />
                            }
                            label="defines its governance"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSelfProcessDefined}
                                    onChange={(e) => setIsSelfProcessDefined(e.target.checked)}
                                    name="selfprocess"
                                />
                            }
                            label="defines its processes"
                        />
                    </Box>

                    <Typography variant="h6" gutterBottom>Organization: the solution...</Typography>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel>is executing this task</InputLabel>
                        <Select
                            value={classifier}
                            onChange={(e) => setClassifier(e.target.value)}
                            label="is executing this task"
                        >
                            <MenuItem value="Classification_nonlinear">Classification_nonlinear</MenuItem>
                            <MenuItem value="Clustering_linear">Clustering_linear</MenuItem>
                            <MenuItem value="Regression">Regression</MenuItem>
                            <MenuItem value="None">None</MenuItem>
                        </Select>
                    </FormControl>

                    <Typography variant="h6" gutterBottom>Self-Organization: the solution...</Typography>
                    <Box sx={{mb: 2}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isReinforcing}
                                    onChange={(e) => setIsReinforcing(e.target.checked)}
                                    name="reinforce"
                                />
                            }
                            label="is reinforcing itself"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSelfSelecting}
                                    onChange={(e) => setIsSelfSelecting(e.target.checked)}
                                    name="selfselect"
                                />
                            }
                            label="is selecting by itself"
                        />
                    </Box>

                    <Typography variant="h6" gutterBottom>Data and Process Management: the solution...</Typography>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel>accessing data</InputLabel>
                        <Select
                            value={dataAccess}
                            onChange={(e) => setDataAccess(e.target.value)}
                            label="accessing data"
                        >
                            <MenuItem value="fully">fully</MenuItem>
                            <MenuItem value="partially">partially (no personal data)</MenuItem>
                            <MenuItem value="None">None</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{mb: 2}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isDataRedundant}
                                    onChange={(e) => setIsDataRedundant(e.target.checked)}
                                    name="dataredundant"
                                />
                            }
                            label="requiring data redundancy"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isDataHeterogenous}
                                    onChange={(e) => setIsDataHeterogenous(e.target.checked)}
                                    name="dataheterogeneous"
                                />
                            }
                            label="accessing heterogeneous data"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isProcessingIandK}
                                    onChange={(e) => setIsProcessingIandK(e.target.checked)}
                                    name="infknoprocessing"
                                />
                            }
                            label="processing information and knowledge"
                        />
                    </Box>

                    <Typography variant="h6" gutterBottom>Interaction: the solution...</Typography>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel>Requiring performance of</InputLabel>
                        <Select
                            value={performance}
                            onChange={(e) => setPerformance(e.target.value)}
                            label="Requiring performance of"
                        >
                            <MenuItem value="real-time">Real-time</MenuItem>
                            <MenuItem value="near real-time">Near real-time</MenuItem>
                            <MenuItem value="batch">Batch</MenuItem>
                            <MenuItem value="none">None</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel>Requiring state of performance</InputLabel>
                        <Select
                            value={performanceQuality}
                            onChange={(e) => setPerformanceQuality(e.target.value)}
                            label="Requiring state of performance"
                        >
                            <MenuItem value="productive_state">Productive</MenuItem>
                            <MenuItem value="proof_of_concept">Proof-of-Concept</MenuItem>
                            <MenuItem value="prototype">Prototype</MenuItem>
                            <MenuItem value="none">None</MenuItem>
                        </Select>
                    </FormControl>

                    <Typography variant="h6" gutterBottom>Business Area: the solution...</Typography>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel>Applied in</InputLabel>
                        <Select
                            value={businessArea}
                            onChange={(e) => setBusinessArea(e.target.value)}
                            label="Applied in"
                        >
                            <MenuItem value="Healthcare">Healthcare</MenuItem>
                            <MenuItem value="Production">Production</MenuItem>
                            <MenuItem value="Risk-Management">Risk-Management</MenuItem>
                            <MenuItem value="Mobility">Mobility</MenuItem>
                            <MenuItem value="none">None</MenuItem>
                        </Select>
                    </FormControl>

                    <Typography variant="h6" gutterBottom>System Orientation: the solution...</Typography>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel>Applied based on an architecture that is</InputLabel>
                        <Select
                            value={systemOrientation}
                            onChange={(e) => setSystemOrientation(e.target.value)}
                            label="Applied based on an architecture that is"
                        >
                            <MenuItem value="Centralized">Centralized</MenuItem>
                            <MenuItem value="Decentralized">Decentralized</MenuItem>
                            <MenuItem value="Fragmented">Fragmented</MenuItem>
                            <MenuItem value="none">None</MenuItem>
                        </Select>
                    </FormControl>

                    <Typography variant="h6" gutterBottom>Approach: the solution...</Typography>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel>Applying</InputLabel>
                        <Select
                            value={approach}
                            onChange={(e) => setApproach(e.target.value)}
                            label="Applying"
                        >
                            <MenuItem value="Deep Learning">Deep Learning</MenuItem>
                            <MenuItem value="Machine Learning">Machine Learning</MenuItem>
                            <MenuItem value="Statistical Learning">Statistical Learning</MenuItem>
                            <MenuItem value="Text Analysis">Text Analysis</MenuItem>
                            <MenuItem value="Geometry">Geometry</MenuItem>
                            <MenuItem value="None">None</MenuItem>
                        </Select>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleBuildSubGraph}
                disabled={isBuilding}
                sx={{mb: 2}}
            >
                {isBuilding ? 'Building Graph...' : 'Build Sub-Graph'}
            </Button>

            {isBuilding && (
                <Typography variant="body2" color="text.secondary" sx={{mt: 2, mb: 2}}>
                    Building the sub-graphs... This process might take a few minutes (estimated 5-10 minutes). Please wait.
                </Typography>
            )}

            {error && (
                <Typography color="error" sx={{mt: 2, mb: 2}}>
                    Error: {error}
                </Typography>
            )}
        </form>
    )
        ;
};
export default Form;