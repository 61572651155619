import React from 'react';
import './index.css';
import Routing from "./Routing";
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
    <Routing />
);


