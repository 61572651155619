import "./App.css";
import React from 'react';
import Container from '@mui/material/Container';
import Form from './Form';

export default function App() {

    return (
        <Container>

            <Form/>

        </Container>
    );
}



