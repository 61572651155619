import './App.css';
import React, {useRef, useEffect, useMemo, useState} from 'react';
import * as d3 from 'd3';
import {drag} from 'd3-drag';
import {forceSimulation, forceLink, forceManyBody, forceCenter, forceCollide} from "d3-force";


function Graph({triples}) {
    const svgRef = useRef(null);
    const width = 1800;
    const height = 1500;
    const [dimensions, setDimensions] = useState({width: 1600, height: 1200});
    const [description, setDescription] = useState("");
    const {nodes, links} = useMemo(() => {
        const nodeMap = new Map();
        const links = [];

        for (let i = 0; i < triples.length; i++) {
            const innerArr = triples[i];
            for (const key in innerArr) {
                const triple = innerArr[key];
                const tripleKeys = Object.keys(triple);
                const tripleObj = triple[tripleKeys[0]];

                const [source, link, target] = tripleObj.split(" ").map(str => str.trim());

                if (!nodeMap.has(source)) {
                    nodeMap.set(source, {id: source});
                }
                if (!nodeMap.has(target)) {
                    nodeMap.set(target, {id: target});
                }

                const linkLabel = link.split("_").join(" ");
                links.push({source, target, label: linkLabel});
            }
        }

        return {
            nodes: Array.from(nodeMap.values()),
            links
        };
    }, [triples]);

    useEffect(() => {
        function handleResize() {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight * 0.8  // 80% of viewport height
            });
        }

        window.addEventListener('resize', handleResize);
        handleResize();  // Set initial size

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        try {
            const svg = d3.select(svgRef.current);

            // Clear previous rendering
            svg.selectAll("*").remove();

            // Add zoom behavior
            const zoom = d3.zoom()
                .scaleExtent([0.1, 4])
                .on("zoom", (event) => {
                    g.attr("transform", event.transform);
                });

            svg.call(zoom);

            // Create a group for the graph
            const g = svg.append("g");

            // Create D3 force simulation
            const simulation = forceSimulation(nodes)
                .force("link", forceLink(links).id(d => d.id).distance(150))
                .force("charge", forceManyBody().strength(-1000))
                .force("center", forceCenter(dimensions.width / 2, dimensions.height / 2))
                .force("collision", forceCollide().radius(30));

            // Define drag behavior for nodes
            function dragNode(simulation) {
                function started(event, d) {
                    if (!event.active) simulation.alphaTarget(0.3).restart();
                    d.fx = d.x;
                    d.fy = d.y;
                }

                function dragged(event, d) {
                    d.fx = event.x;
                    d.fy = event.y;
                }

                function ended(event, d) {
                    if (!event.active) simulation.alphaTarget(0);
                    d.fx = null;
                    d.fy = null;
                }

                return drag()
                    .on("start", started)
                    .on("drag", dragged)
                    .on("end", ended);
            }

            // Add links
            const link = g
                .selectAll(".link")
                .data(links)
                .join("line")
                .attr("class", "link")
                .style("stroke", "#999")
                .style("stroke-opacity", 0.6)
                .style("stroke-width", 2);

            // Add link labels
            const linkLabel = g
                .selectAll(".link-label")
                .data(links)
                .join("text")
                .attr("class", "link-label")
                .text(d => d.label)
                .attr("font-size", "8px")
                .attr("fill", "#555");

            // Define color scale for nodes
            const color = d3.scaleOrdinal(d3.schemeCategory10);

            // Add nodes
            const node = g
                .selectAll(".node")
                .data(nodes)
                .join("circle")
                .attr("class", "node")
                .attr("r", 10)
                .attr("fill", d => color(d.group))
                .attr("stroke", "#fff")
                .attr("stroke-width", 2)
                .call(dragNode(simulation));

            // Add node labels
            const nodeLabel = g
                .selectAll(".node-label")
                .data(nodes)
                .join("text")
                .attr("class", "node-label")
                .text(d => d.id)
                .attr("font-size", "10px")
                .attr("dx", 12)
                .attr("dy", 4);

            function ticked() {
                link
                    .attr("x1", d => d.source.x)
                    .attr("y1", d => d.source.y)
                    .attr("x2", d => d.target.x)
                    .attr("y2", d => d.target.y);

                linkLabel
                    .attr("x", d => (d.source.x + d.target.x) / 2)
                    .attr("y", d => (d.source.y + d.target.y) / 2);

                node.attr("cx", d => d.x).attr("cy", d => d.y);

                nodeLabel.attr("x", d => d.x).attr("y", d => d.y);
            }

            simulation.nodes(nodes).on("tick", ticked);
            simulation.force("link").links(links);

            // Auto-scale to fit
            function autoScale() {
                const bounds = g.node().getBBox();
                const dx = bounds.width;
                const dy = bounds.height;
                const x = bounds.x + dx / 2;
                const y = bounds.y + dy / 2;
                const scale = 0.9 / Math.max(dx / width, dy / height);
                const translate = [width / 2 - scale * x, height / 2 - scale * y];

                svg.transition()
                    .duration(750)
                    .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
            }

            // Call auto-scale after the simulation has settled
            simulation.on("end", autoScale);

            // Generate description
            const generatedDescription = generateDescription(nodes, links);
            setDescription(generatedDescription);

        } catch (error) {
            console.error("Error in graph rendering:", error);
        }
    }, [links, nodes, triples, dimensions]);

    function generateDescription(nodes, links) {
        const nodeCount = nodes.length;
        const linkCount = links.length;
        const nodeGroups = [...new Set(nodes.map(node => node.group))];
        const relationshipTypes = [...new Set(links.map(link => link.label))];

        return `This graph represents a solution with ${nodeCount} components across ${nodeGroups.length} categories: ${nodeGroups.join(", ")}. 
        These components are connected through ${linkCount} relationships, including ${relationshipTypes.join(", ")}. 
        This structure suggests a ${nodeCount > 10 ? "complex" : "simple"} system with ${linkCount > nodeCount ? "high" : "moderate"} interconnectivity. 
        Key components include ${nodes.slice(0, 3).map(node => node.id).join(", ")}, which play central roles in the system.`;
    }

    return (
        <div style={{width: '100%', height: '100vh'}}>
            {/* Description box */}
            <div style={{
                marginBottom: '20px',
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '5px',
                backgroundColor: '#fff'
            }}>
                <h3>Graph Description:</h3>
                <p>{description}</p>
            </div>

            {/* Graph */}
            <svg ref={svgRef} width={dimensions.width} height={dimensions.height}>
                <rect width="100%" height="100%" fill="#f0f0f0"/>
            </svg>
        </div>
    );
}


export default Graph;
