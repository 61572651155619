import React from "react";
import {
  Route,
  Routes,
  BrowserRouter
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import App from './App';
import Home from './Home';
import Results from './Results';
import ResultGraph from './ResultGraph';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink
} from "@apollo/client";

const createApolloClient = () => {
  const link = new HttpLink({
    uri: 'https://graphql.findyoursolution.ai'
  })
  return new ApolloClient({
    link,
    cache: new InMemoryCache()
  })
}

const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function Routing() {
  const classes = useStyles();

  return (
    <ApolloProvider client={createApolloClient()}>
      <BrowserRouter>
        <div className={classes.root}>
          <AppBar position="static" style={{background: '#595959'}}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Data-driven Solution Finder
              </Typography>
              <Button color="inherit" href="/">Home</Button>
              <Button color="inherit" href="/app">App</Button>
            </Toolbar>
          </AppBar>
        </div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/app" element={<App />} />
          <Route exact path="/results" element={<Results />} />
          <Route exact path="/resultgraph" element={<ResultGraph />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default Routing;
